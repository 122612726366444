import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import mixpanel from 'mixpanel-browser'
import { motion, useInView } from 'motion/react'

import { Reveal } from '@/components/Reveal'

export const Footer = () => {
    const { t } = useTranslation()

    const footerRef = useRef(null)

    const isInView = useInView(footerRef)

    useEffect(() => {
        if (isInView) mixpanel.track('Footer')
    }, [isInView])

    return (
        <Reveal ref={footerRef}>
            {revealProps => (
                <motion.footer className='bg-gray-900 py-12' {...revealProps}>
                    <div className='container mx-auto px-4'>
                        <div className='flex justify-between gap-8'>
                            <img className='h-8' src='/boothbits-logo.svg' alt='BoothBits logo' />
                            <div>
                                <ul className='space-y-2'>
                                    <li>
                                        <Link to='/tcs' className='text-gray-400 hover:text-white'>
                                            {t('footer.terms', 'Terms and Conditions')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/privacy' className='text-gray-400 hover:text-white'>
                                            {t('footer.privacy', 'Privacy Policy')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='mt-8 border-t border-gray-700 pt-8 text-center text-gray-400'>
                            <p>&copy; 2024 BoothBits. {t('footer.rights', 'All rights reserved.')}</p>
                        </div>
                    </div>
                </motion.footer>
            )}
        </Reveal>
    )
}
