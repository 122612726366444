import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { BarChart3, Lightbulb, MessageCircle } from 'lucide-react'
import mixpanel from 'mixpanel-browser'
import { motion, useInView } from 'motion/react'

import { Reveal } from '@/components/Reveal'

export const Features = () => {
    const { t } = useTranslation()

    const sectionRef = useRef(null)

    const isInView = useInView(sectionRef)

    useEffect(() => {
        if (isInView) mixpanel.track('Features')
    }, [isInView])

    return (
        <section ref={sectionRef} id='features' className='py-20'>
            <div className='container mx-auto px-4'>
                <Reveal>
                    {revealProps => (
                        <motion.h2 className='mb-12 text-center text-3xl font-bold' {...revealProps}>
                            {t('features.title', 'Key Features')}
                        </motion.h2>
                    )}
                </Reveal>
                <div className='grid gap-8 md:grid-cols-3'>
                    {[
                        {
                            title: t('features.feature1.title', 'Real-time Analytics'),
                            description: t(
                                'features.feature1.description',
                                'Get instant insights from attendee feedback as it comes in.',
                            ),
                            img: <BarChart3 className='mb-4 h-12 w-12 text-purple-400' />,
                        },
                        {
                            title: t('features.feature2.title', 'Smart Surveys'),
                            description: t(
                                'features.feature2.description',
                                'AI-powered surveys that adapt to attendee responses for deeper insights.',
                            ),
                            img: <MessageCircle className='mb-4 h-12 w-12 text-purple-400' />,
                        },
                        {
                            title: t('features.feature3.title', 'Actionable Recommendations'),
                            description: t(
                                'features.feature3.description',
                                'Receive AI-generated suggestions to improve your future events.',
                            ),
                            img: <Lightbulb className='mb-4 h-12 w-12 text-purple-400' />,
                        },
                    ].map(({ title, description, img }, index) => (
                        <Reveal key={index}>
                            {revealProps => (
                                <motion.div className='rounded-lg bg-gray-700 p-6' {...revealProps}>
                                    {img}
                                    <h3 className='mb-2 text-xl font-semibold'>{title}</h3>
                                    <p>{description}</p>
                                </motion.div>
                            )}
                        </Reveal>
                    ))}
                </div>
            </div>
        </section>
    )
}
