import { useEffect } from 'react'

import mixpanel from 'mixpanel-browser'

import { redirectToSales } from './lib/redirectToSales'
import { Faqs } from './sections/Faqs'
import { Features } from './sections/Features'
import { Footer } from './sections/Footer'
import { Header } from './sections/Header'
import { HowItWorks } from './sections/HowItWorks'
import { Intro } from './sections/Intro'
import { ScheduleCall } from './sections/ScheduleCall'

export function LandingPage() {
    useEffect(() => {
        mixpanel.track_pageview()
    }, [])

    return (
        <>
            <div className='min-h-screen bg-gray-900 text-white'>
                <Header />

                <main>
                    <Intro onRedirectToSales={redirectToSales} />

                    <HowItWorks />

                    <Features />

                    <Faqs />

                    <ScheduleCall />
                </main>

                <Footer />
            </div>
        </>
    )
}
