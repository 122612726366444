import { RefObject, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { AnimationControls, Transition, Variant, useAnimation, useInView } from 'motion/react'

const INITIAL_ANIMATION = 'hidden'
const START_ANIMATION = 'visible'

const defaultTransition: Transition = {
    duration: 0.5,
    delay: 0.25,
}

/**
 * TODO the Reveal component cannot infer which html element its child will be, so we use `any` here
 * An option would be to have an `as`/`tag` prop in Reveal which accepts any html element tag, and build
 * the motion element with it, e.g. if `as` is `h2` then we need a `motion.h2`
 * This would change the API of Reveal to directly return the desired element instead of using render props
 */
type RefType = any

type UseRevealProps<T extends RefType = RefType> = {
    ref: RefObject<T>
    controls: AnimationControls
    variants: {
        [INITIAL_ANIMATION]: Variant
        [START_ANIMATION]: Variant
    }
    initial: string
    animate: AnimationControls
    transition: Transition
}

const useReveal = <T extends RefType>({
    ref,
    ...revealPropsOverride
}: Partial<UseRevealProps<T>> & { ref: UseRevealProps['ref'] }): UseRevealProps<T> => {
    const isInView = useInView(ref, { once: true })
    const controls = useAnimation()

    useEffect(() => {
        if (isInView) controls.start(START_ANIMATION)
    }, [isInView, controls])

    const { variants, transition, ...overrides } = revealPropsOverride

    return {
        ref,
        controls,
        variants: {
            [INITIAL_ANIMATION]: { opacity: 0, ...variants?.[INITIAL_ANIMATION] },
            [START_ANIMATION]: { opacity: 1, ...variants?.[START_ANIMATION] },
        },
        initial: INITIAL_ANIMATION,
        animate: controls,
        transition: { ...defaultTransition, ...transition },
        ...overrides,
    }
}

type RevealProps = {
    children: (revealProps: UseRevealProps) => React.ReactNode
} & Partial<UseRevealProps>

export const Reveal = forwardRef<HTMLElement, RevealProps>(({ children, ...revealPropsOverride }, ref) => {
    const innerRef = useRef<HTMLElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLElement, [])

    const revealProps = useReveal<any>({ ...revealPropsOverride, ref: innerRef })

    return <>{children(revealProps)}</>
})
