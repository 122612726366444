import { useTranslation } from 'react-i18next'

import { motion } from 'motion/react'

import { LanguageSelector } from '@/components/LanguageSelector'

export const Header = () => {
    const { t } = useTranslation()

    return (
        <header className='container mx-auto flex items-center justify-between px-4 py-6'>
            <motion.div
                className='text-2xl font-bold'
                variants={{
                    hidden: { opacity: 0, x: -75 },
                    visible: { opacity: 1, x: 0 },
                }}
                initial='hidden'
                animate='visible'
                transition={{ duration: 0.5, delay: 1 }}
            >
                <a className='flex items-center justify-center' href='/'>
                    <img className='h-12' src='/boothbits-logo.svg' alt='BoothBits logo' />
                </a>
            </motion.div>
            <motion.nav
                className='flex gap-8'
                variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                }}
                initial='hidden'
                animate='visible'
                transition={{ duration: 0.5, delay: 1.75 }}
            >
                <LanguageSelector />
                <a href='#faqs' className='text-gray-300 transition-colors hover:text-white'>
                    {t('header.faqs', 'FAQs')}
                </a>
            </motion.nav>
        </header>
    )
}
