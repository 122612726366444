import mixpanel from 'mixpanel-browser'

import { isTestEnvironment } from '../environment.ts'

const projectToken = isTestEnvironment
    ? '352d226ab918fde801ce6c5cb3b0e333' // Testing
    : 'bbb91f1604af9c3f3bf8a96c9663c472' // Production

mixpanel.init(projectToken, {
    api_host: 'https://mixpanel-tracking-proxy-7tducinnmq-uc.a.run.app',
    debug: isTestEnvironment,
})
