import { useTranslation } from 'react-i18next'

import { PhoneCall } from 'lucide-react'
import { motion } from 'motion/react'

import { Reveal } from '@/components/Reveal'
import { Button } from '@/components/ui/button'
import { useIsMobile } from '@/lib/styles/useIsMobile'

interface Props {
    onRedirectToSales: () => void
}

export const Intro = ({ onRedirectToSales }: Props) => {
    const { t, i18n } = useTranslation()
    const isMobile = useIsMobile()

    const dashboardImg =
        i18n.language === 'es' ? '/boothbits-landing-dashboard-es.png' : '/boothbits-landing-dashboard-en.png'

    const checkIcon = (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            className='h-[18px] w-[18px] text-primary'
        >
            <path
                fillRule='evenodd'
                d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                clipRule='evenodd'
            ></path>
        </svg>
    )

    return (
        <section
            id='intro'
            className='mx-auto flex w-full max-w-7xl flex-col items-center justify-center gap-14 overflow-hidden px-0.5 pb-12 pt-8 max-lg:px-4 lg:gap-16 lg:pb-24 lg:pt-14'
        >
            <div className='text-center'>
                <div className='mb-16 lg:mb-12'>
                    <motion.h1
                        variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            duration: 0.5,
                            delay: 0.25,
                        }}
                        className='text-4xl font-extrabold tracking-tight lg:text-6xl'
                    >
                        {t('intro.title', 'Feedback Management improved with AI')}
                    </motion.h1>
                    <Reveal
                        variants={{
                            hidden: { x: -75, y: isMobile ? 10 : 30, rotateZ: -4 },
                            visible: { x: 0, y: isMobile ? 10 : 30, rotateY: 0, rotateZ: -4 },
                        }}
                        transition={{ delay: 0.5 }}
                    >
                        {revealProps => (
                            <motion.span
                                className='flex items-center justify-center gap-0.5 whitespace-nowrap text-base font-normal tracking-wide lg:mt-4 lg:justify-end lg:text-xl'
                                {...revealProps}
                            >
                                {t('intro.subtitle', 'better feedback, better events')}
                                <span className='text-green ml-1 opacity-100'>✔</span>
                            </motion.span>
                        )}
                    </Reveal>
                </div>
                <div className='mx-auto w-fit text-left'>
                    <Reveal transition={{ delay: 1.25 }}>
                        {revealProps => (
                            <motion.p
                                className='text-base-content mb-4 text-lg font-medium leading-relaxed lg:text-xl'
                                {...revealProps}
                            >
                                {t('intro.listTitle', 'Manage experience data from your events')}
                            </motion.p>
                        )}
                    </Reveal>
                    <ul className='text-base-secondary mb-8 space-y-0.5 text-lg leading-relaxed'>
                        <Reveal transition={{ delay: 1.5 }}>
                            {revealProps => (
                                <motion.li className='flex items-center justify-start gap-2' {...revealProps}>
                                    {checkIcon}
                                    {t('intro.firstListItem', 'Analyze real feedback from attendees')}
                                </motion.li>
                            )}
                        </Reveal>
                        <Reveal transition={{ delay: 1.6 }}>
                            {revealProps => (
                                <motion.li className='flex items-center justify-start gap-2' {...revealProps}>
                                    {checkIcon}
                                    {t('intro.secondtListItem', 'Get AI-powered insights and summaries')}
                                </motion.li>
                            )}
                        </Reveal>
                        <Reveal transition={{ delay: 1.7 }}>
                            {revealProps => (
                                <motion.li className='flex items-center justify-start gap-2' {...revealProps}>
                                    {checkIcon}
                                    {t('intro.thirdListItem', 'Understand ratings at a glance')}
                                </motion.li>
                            )}
                        </Reveal>
                    </ul>
                    <Reveal transition={{ delay: 2 }}>
                        {revealProps => (
                            <motion.div className='flex max-w-sm flex-col space-y-1.5' {...revealProps}>
                                <Button
                                    className='bg-gradient-to-r from-purple-600 to-pink-500 px-8 py-4 text-lg hover:opacity-90'
                                    onClick={onRedirectToSales}
                                >
                                    <PhoneCall className='mr-2 h-5 w-5' />
                                    {t('callToAction.button', 'I want a demo')}
                                </Button>
                                <p className='text-base-secondary text-center text-sm opacity-90'>
                                    {t('intro.buttonSubtitle', 'Start producing better events')}
                                </p>
                            </motion.div>
                        )}
                    </Reveal>
                </div>
            </div>
            <motion.div
                className='w-full rounded-[1.3rem] border-4 p-1.5'
                variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                }}
                initial='hidden'
                animate='visible'
                transition={{
                    duration: 0.5,
                    delay: 2.25,
                }}
            >
                <img width='1000' height='1000' className='w-full rounded-[1.3rem]' src={dashboardImg} />
            </motion.div>
        </section>
    )
}
