import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronDown, ChevronUp } from 'lucide-react'
import mixpanel from 'mixpanel-browser'
import { motion, useInView } from 'motion/react'

import { Reveal } from '@/components/Reveal'

export const Faqs = () => {
    const sectionRef = useRef(null)
    const [openFaq, setOpenFaq] = useState<number | null>(null)

    const { t } = useTranslation()

    const isInView = useInView(sectionRef)

    useEffect(() => {
        if (isInView) mixpanel.track('Faqs')
    }, [isInView])

    const toggleFaq = (index: number) => {
        setOpenFaq(openFaq === index ? null : index)
    }

    const faqs = [
        {
            question: t('faq.question1', 'Is the feedback collected by BoothBits public?'),
            answer: t(
                'faq.answer1',
                'No, it is only for you. All information is private, giving you exclusive access to the data, including feedback from your staff to keep both attendees and team under control.',
            ),
        },
        {
            question: t('faq.question2', 'Can I use Boothbits at events of any size?'),
            answer: t(
                'faq.answer2',
                'Boothbits is scalable and can adapt to events of any size, from small concerts to large festivals or international conferences. We offer flexible plans that fit the magnitude of your event.',
            ),
        },
        {
            question: t('faq.question3', 'What types of reports can I generate with Boothbits?'),
            answer: t(
                'faq.answer3',
                'Boothbits generates detailed reports by category (strengths, issues, requests) and by department (music, staff, security, etc.). You can access visual summaries, interactive charts, and actionable recommendations, all from a centralized dashboard.',
            ),
        },
        {
            question: t('faq.question4', 'Can I customize the feedback surveys?'),
            answer: t(
                'faq.answer4',
                'Yes, you can fully customize the surveys to suit the specific needs of your event. Boothbits allows you to adjust the questions and format to obtain the most relevant feedback for you.',
            ),
        },
        {
            question: t('faq.question5', 'How long does it take to see the results?'),
            answer: t(
                'faq.answer5',
                'Results are seen almost in real-time. From the moment feedback starts being collected, you can view the analysis on our dashboard. This allows you to make immediate adjustments and plan improvements for future events.',
            ),
        },
        {
            question: t('faq.question6', 'Is it difficult to implement Boothbits at my events?'),
            answer: t(
                'faq.answer6',
                'Boothbits is easy to implement. We provide all the necessary tools to ensure the feedback collection process is smooth and does not interrupt the event. Additionally, our team will assist you at all times to ensure the system works optimally.',
            ),
        },
    ]

    return (
        <section ref={sectionRef} id='faqs' className='py-20'>
            <div className='container mx-auto px-4'>
                <Reveal>
                    {revealProps => (
                        <motion.h3 className='mb-8 text-center text-3xl font-bold text-white' {...revealProps}>
                            {t('faqs.title', 'Frequently Asked Questions')}
                        </motion.h3>
                    )}
                </Reveal>

                <div className='mx-auto max-w-3xl space-y-4'>
                    {faqs.map((faq, index) => (
                        <Reveal key={index}>
                            {revealProps => (
                                <motion.div
                                    className='rounded-lg border border-gray-700 transition duration-300 ease-in-out focus-within:ring-2 focus-within:ring-purple-600'
                                    {...revealProps}
                                >
                                    <button
                                        className={`flex w-full items-center justify-between bg-gray-800 p-4 text-left transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none ${
                                            openFaq === index ? 'rounded-t-lg' : 'rounded-lg'
                                        }`}
                                        onClick={() => toggleFaq(index)}
                                    >
                                        <span className='font-bold text-purple-400'>{faq.question}</span>
                                        {openFaq === index ? (
                                            <ChevronUp className='h-5 w-5 text-gray-400' />
                                        ) : (
                                            <ChevronDown className='h-5 w-5 text-gray-400' />
                                        )}
                                    </button>
                                    {openFaq === index && (
                                        <div className='rounded-b-lg bg-gray-700 p-4'>
                                            <p className='text-gray-300'>{faq.answer}</p>
                                        </div>
                                    )}
                                </motion.div>
                            )}
                        </Reveal>
                    ))}
                </div>
            </div>
        </section>
    )
}
