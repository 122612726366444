import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { PhoneCall } from 'lucide-react'
import mixpanel from 'mixpanel-browser'
import { motion, useInView } from 'motion/react'

import { Reveal } from '@/components/Reveal'
import { Button } from '@/components/ui/button'
import { redirectToSales } from '@/lib/redirectToSales'

const MotionButton = motion.create(Button)

export const ScheduleCall = () => {
    const { t } = useTranslation()

    const sectionRef = useRef(null)

    const isInView = useInView(sectionRef)

    useEffect(() => {
        if (isInView) mixpanel.track('ScheduleCall')
    }, [isInView])

    return (
        <section ref={sectionRef} id='schedule-call' className='bg-gray-800 pt-20'>
            <div className='container mx-auto px-4 text-center'>
                <Reveal>
                    {revealProps => (
                        <motion.h2 className='mb-8 text-2xl font-bold' {...revealProps}>
                            {t('callToAction.title', 'Let’s improve together, schedule a call.')}
                        </motion.h2>
                    )}
                </Reveal>
                <Reveal>
                    {revealProps => (
                        <motion.p className='mb-8' {...revealProps}>
                            {t(
                                'callToAction.description',
                                'Together, we’ll create a customized experience that aligns with your goals. We believe in doing things right as we grow, building a strong foundation as we strive for excellence in every step.',
                            )}
                        </motion.p>
                    )}
                </Reveal>
                <Reveal>
                    {revealProps => (
                        <MotionButton
                            className='bg-gradient-to-r from-purple-600 to-pink-500 px-8 py-4 text-lg hover:opacity-90'
                            onClick={redirectToSales}
                            {...revealProps}
                        >
                            <PhoneCall className='mr-2 h-5 w-5' />
                            {t('callToAction.button', 'I want a demo')}
                        </MotionButton>
                    )}
                </Reveal>
                <Reveal>
                    {revealProps => (
                        <motion.p className='mt-8 font-bold italic' {...revealProps}>
                            {t('callToAction.signature', 'BoothBits Team')}
                        </motion.p>
                    )}
                </Reveal>
            </div>

            <Reveal>
                {revealProps => (
                    <motion.div className='bg-gray-800 pb-20 pt-12' {...revealProps}>
                        <div className='mx-auto flex max-w-3xl justify-around space-y-4'>
                            <img className='h-[150px]' src='/laptop-dashboard.svg' alt='Laptop Dashboard' />
                            <img className='h-[150px]' src='/cellphone-form.svg' alt='Cellphone form' />
                        </div>
                    </motion.div>
                )}
            </Reveal>
        </section>
    )
}
