import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowDown } from 'lucide-react'
import mixpanel from 'mixpanel-browser'
import { motion, useInView } from 'motion/react'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

import { Reveal } from '../components/Reveal'

export const HowItWorks = () => {
    const { t } = useTranslation()
    const sectionRef = useRef(null)

    const isInView = useInView(sectionRef)

    useEffect(() => {
        if (isInView) mixpanel.track('HowItWorks')
    }, [isInView])

    return (
        <section ref={sectionRef} id='how-it-works' className='py-20'>
            <div className='container mx-auto px-4'>
                <Reveal>
                    {revealProps => (
                        <motion.h2 className='mb-12 text-center text-3xl font-bold' {...revealProps}>
                            {t('howItWorks.title', 'How it works')}
                        </motion.h2>
                    )}
                </Reveal>
                <div className='grid gap-8 md:grid-cols-4'>
                    {[
                        {
                            title: t('howItWorks.step1.title', 'Collect Opinions'),
                            description: t(
                                'howItWorks.step1.description',
                                'Get insights from attendees through dynamic surveys',
                            ),
                            imgSrc: 'cellphone-form.svg',
                        },
                        {
                            title: t('howItWorks.step2.title', 'AI Analysis'),
                            description: t(
                                'howItWorks.step2.description',
                                'Our AI processes and analyzes the collected data',
                            ),
                            imgSrc: 'ai-analysis.jpg',
                        },
                        {
                            title: t('howItWorks.step3.title', 'Generate Reports'),
                            description: t(
                                'howItWorks.step3.description',
                                'Receive detailed reports of your event data',
                            ),
                            imgSrc: 'report-example.png',
                        },
                        {
                            title: t('howItWorks.step4.title', 'Improve Your Events'),
                            description: t('howItWorks.step4.description', 'Implement AI-driven recommendations'),
                            imgSrc: 'improvement.png',
                        },
                    ].map((step, index) => (
                        <Reveal key={index}>
                            {revealProps => (
                                <motion.div {...revealProps}>
                                    <Card className='border-2'>
                                        <CardHeader className='relative h-36 bg-pink-200'>
                                            <img
                                                src={step.imgSrc}
                                                className='absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover'
                                            />
                                            <div className='z-10 flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-r from-purple-400 to-pink-500 text-2xl font-bold'>
                                                {index + 1}
                                            </div>
                                        </CardHeader>
                                        <CardContent>
                                            <CardTitle>{step.title}</CardTitle>
                                            <CardDescription>{step.description}</CardDescription>
                                        </CardContent>
                                    </Card>
                                    {index < 3 && (
                                        <ArrowDown className='mx-auto mt-4 h-8 w-8 text-purple-500 sm:hidden' />
                                    )}
                                </motion.div>
                            )}
                        </Reveal>
                    ))}
                </div>
            </div>
        </section>
    )
}
